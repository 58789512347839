/* stylelint-disable selector-id-pattern */
@import 'styles/mixins/media-queries';
@import 'styles/constants';
@import 'styles/colors';

$container-padding-bottom: 40px;
$container-padding-top: 40px;

$heading-padding-bottom: 22px;
$heading-padding-top: 22px;

:root {
	--blue-primary: $brand-primary;
	--promo-bar-height: 0;
}

#container-404 {
	height: 100% !important;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-family: var(--neue-montreal), sans-serif;
	font-weight: 300;
	color: #fff;
	overflow: hidden scroll;
	height: 100%;
	scroll-padding-top: 56px;

	&.scroll-snap {
		scroll-snap-type: y proximity;
	}

	&.disable-scroll-snap {
		scroll-snap-type: none;
	}
}

.page_lock {
	overflow: hidden;
	position: fixed;
	overscroll-behavior: none;
	height: 100vh;
}

.no_overscroll {
	overscroll-behavior: none;
}

.disable-transitions {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

body {
	font-size: 17px;
	margin: 0;
	padding: 0;
	height: 100%;
	background-color: #fff;
	position: relative;
	-webkit-text-size-adjust: 100%;

	img[width='1'] {
		@include visually-hidden;
	}

	&:has(main[class^='membership-details_root']) {
		background-color: black;
	}
}

#__next > div:first-child {
	width: 100%;
	position: relative;
}

.selected_menu {
	border-bottom: solid 2px #fff;
	transition: all ease 0.1s;
}

.striped {
	-webkit-mask: url('https://eightsleep.imgix.net/Uninterrupted_Text.png');
	-webkit-mask-size: cover;
}

a {
	color: $brand-primary;
	text-decoration: none;

	&:hover {
		text-decoration: underline;

		&.btn,
		&.btn-lnk,
		&[class*='button'],
		&[class*='Button'],
		&.no-underline {
			text-decoration: none;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
}

h3,
h4,
h5,
h6 {
	font-family: var(--neue-montreal), sans-serif;
	font-weight: 300;
}

.alt {
	color: #000;
}

.alt_444 {
	color: #444;
}

.secondary {
	color: #474951;
}

.center-text,
.ct,
.tac {
	text-align: center !important;
}

.p-tac {
	font-size: 16px;
	line-height: 140%;
	@include md-up {
		line-height: 25px;
		font-size: 18px;
	}
	@include xs-only {
		font-size: 14px;
		line-height: 140%;
	}
}

.strong {
	font-weight: 600;
}

ul,
ol {
	margin: 0;
	padding: 0;
}

a,
button,
input,
select,
textarea,
label,
summary {
	touch-action: manipulation;
}

a:hover,
a:focus,
button:hover,
button.button {
	// outline: none;
}

a.button:hover,
a.button:focus {
	opacity: 1;
	filter: brightness(105%);

	&.shadow {
		box-shadow: 0 0.3em 1.5em 0 rgb(0 0 0 / 35%);
	}
}

input[type='text'] {
	border: 0 none;
}

.page-width {
	max-width: 1170px;
	margin: 0 auto;
}

.zero-auto {
	margin: 0 auto;
}

.link-no-style {
	color: inherit;
	text-decoration: inherit;
}

.no-text-transform {
	text-transform: none;
}

.center-absolute {
	left: 50%;
	transform: translateX(-50%);
}

.bold {
	font-weight: 700 !important;
}

.max-1440 {
	@include md-up {
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
	}
}

.max-1440-margin-left {
	@extend .max-1440;
	@include md-up {
		margin-left: 0;
	}
}

.max-1920 {
	@include md-up {
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;
	}
}

.align-inner-left {
	padding-left: $xs_margin;
	@include md-up {
		padding-left: $md_margin;
	}
}

.align-inner-right {
	padding-right: $xs_margin;
	@include md-up {
		padding-right: $md_margin;
	}
}

.no-margin {
	margin: 0;
}

#___gatsby {
	height: 100%;
}

.slick-slider {
	overflow-x: hidden;
	overflow-y: hidden;
}

.slick-slider .slick-track {
	display: flex !important;
	justify-content: center;
}

.slick-slider .slick-slide {
	width: 100%;
}

li > p {
	margin-bottom: 5px;
	display: inline-block;
}

ul {
	margin-bottom: 20px;
}

.fw {
	width: 100%;
	object-fit: contain;
	align-self: flex-start;
}

.hidden {
	visibility: hidden;
}

.none {
	display: none !important;
}

.bold-heading {
	color: $brand-primary;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 10px;
	font-size: 30px;
	@include md-up {
		font-size: 40px;
	}
}

border-none {
	border: none;
}

.slick-arrow {
	z-index: 150;
	display: flex !important;
	flex-direction: column;
	height: 100%;
}

.product-slider {
	background-color: black;
}

.product-slider .slick-slide > div > div {
	// for YOUTUBE IFRAME in PRODUCT PAGE SLIDER
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.product-slider iframe {
	position: absolute;
	top: 10%;
	left: 0;
	width: 100%;
	height: 100%;
}

.break-md-only {
	@include sm-up {
		display: block;
	}
	@include md-up {
		display: inline-block;
	}
}

#kustomer-root,
iframe#kustomer-ui-sdk-iframe {
	z-index: $z_index_kustomer !important;

	.chatRootIcon__wrapper___pQaa8 {
		background: red;
	}
}

img[src*="//i.geistm.com/x/EightSleep"]
{
	position: fixed;
	width: 0;
	height: 0;
}

.flex-column {
	display: flex;
	flex-direction: column;
	height: 100%;
}

// Global SCSS for Masonry react grid

.my-masonry-grid {
	display: flex;
	margin-left: -30px; /* gutter size offset */
	width: auto;
}

.my-masonry-grid_column {
	padding-left: 30px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	/* change div to reference your elements you put in <Masonry> */
	background: grey;
	margin-bottom: 30px;
}

.inner-paddings-x {
	padding-left: $xs_margin;
	padding-right: $xs_margin;
	@include sm-up {
		padding-left: $sm_margin;
		padding-right: $sm_margin;
	}
	@include lg-up {
		padding-left: $md_margin;
		padding-right: $md_margin;
	}
}

.inner-paddings-x-new {
	padding-left: 34px;
	padding-right: 34px;
	@include sm-up {
		padding-left: 48px;
		padding-right: 48px;
	}
	@include lg-up {
		padding-left: 74px;
		padding-right: 74px;
	}
}

.outer-margins-y {
	margin-top: $xs_primary_padding;
	margin-bottom: $xs_primary_padding;
	@include md-up {
		margin-top: $md_primary_padding;
		margin-bottom: $md_primary_padding;
	}
}

.outer-margins-bottom {
	margin-bottom: $xs_primary_padding;
	@include md-up {
		margin-bottom: $md_primary_padding;
	}
}

.inner-paddings-y {
	padding-top: $xs_primary_padding;
	padding-bottom: $xs_primary_padding;
	@include md-up {
		padding-top: $md_primary_padding;
		padding-bottom: $md_primary_padding;
	}
}

.inner-paddings-top {
	padding-top: $xs_primary_padding;
	@include md-up {
		padding-top: $md_primary_padding;
	}
}

.inner-paddings-bottom {
	padding-bottom: $xs_primary_padding;
	@include md-up {
		padding-bottom: $md_primary_padding;
	}
}

.inner-margins-bottom {
	margin-bottom: $xs_primary_padding;
	@include md-up {
		margin-bottom: $md_primary_padding;
	}
}

.inner-margins-bottom-sm-up {
	margin-bottom: 0;
	@include sm-only {
		margin-bottom: $xs_primary_padding;
	}
	@include md-up {
		margin-bottom: $md_primary_padding;
	}
}

.inner-margins-y {
	margin-top: $xs_primary_padding;
	margin-bottom: $xs_primary_padding;
	@include md-up {
		margin-top: $md_primary_padding;
		margin-bottom: $md_primary_padding;
	}
}

.inner-margins-minor-y {
	margin-top: $xs_secondary_padding;
	margin-bottom: $xs_secondary_padding;
	@include md-up {
		margin-top: $md_secondary_padding;
		margin-bottom: $md_secondary_padding;
	}
}

.inner-paddings-minor-y {
	padding-top: $xs_secondary_padding;
	padding-bottom: $xs_secondary_padding;
	@include md-up {
		padding-top: $md_secondary_padding;
		padding-bottom: $md_secondary_padding;
	}
}

.inner-paddings-minor-top {
	padding-top: $xs_secondary_padding;
	@include md-up {
		padding-top: $md_secondary_padding;
	}
}

.inner-paddings-top-md {
	padding-top: $md_primary_padding;
	@include sm-down {
		padding-top: 0;
	}
}

.inner-paddings-minor-bottom-sm-up {
	padding-bottom: 0;
	@include sm-up {
		padding-bottom: $md_secondary_padding;
	}
}

.inner-paddings-minor-bottom {
	padding-bottom: $xs_secondary_padding;
	@include sm-up {
		padding-bottom: $md_secondary_padding;
	}
}

.inner-margins-minor-top {
	margin-top: $xs_secondary_padding;
	@include md-up {
		margin-top: $md_secondary_padding;
	}
}

.inner-margins-minor-bottom {
	margin-bottom: $xs_secondary_padding;
	@include md-up {
		margin-bottom: $md_secondary_padding;
	}
}

.inner-paddings-left {
	padding-left: $xs_margin;
	@include sm-up {
		padding-left: $sm_margin;
	}
	@include lg-up {
		padding-left: $md_margin;
	}
}

.inner-paddings-right {
	padding-right: $xs_margin;
	@include sm-up {
		padding-right: $sm_margin;
	}
	@include lg-up {
		padding-right: $md_margin;
	}
}

.fw-xs {
	@include xs-only {
		margin-right: 0 !important;
		margin-left: 0 !important;
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}

.heading-mb {
	margin-bottom: $heading-padding-bottom;
}

.heading-mt {
	margin-bottom: $heading-padding-top;
}

.no-mb {
	margin-bottom: 0 !important;
}

.slider-control-bottomcenter {
	bottom: -25px !important;
}

.max {
	margin-left: auto;
	margin-right: auto;
	max-width: 1920px;
}

.strike {
	text-decoration: line-through;
}

.inner-container {
	position: relative;
	margin-left: $xs_margin;
	margin-right: $xs_margin;
	@include sm-up {
		margin-left: $sm_margin;
		margin-right: $sm_margin;
	}
	@include lg-up {
		margin-left: $md_margin;
		margin-right: $md_margin;
	}
}

.blue {
	color: $brand-primary !important;
}

#__next {
	margin-bottom: -20px;

	--button-text-transform: uppercase;
}

.kustomer-app-icon {
	width: 135px !important;
	height: 65px !important;

	.kustomer-app-icon-image {
		background-size: 100% !important;
		width: 135px !important;
		height: 65px !important;
	}

	.minimizedUnreadIcon.kustomer-app-minimized-unread-icon {
		left: 95px !important;
		top: -5px !important;
	}

	.iconMessage {
		> div {
			> div:nth-child(2) {
				border-radius: 9px 9px 0 !important;
			}
		}

		.iconMessageAvatar {
			display: none;
		}
	}
}

@include sm-down {
	.kustomer .kustomer-app-icon.kustomer-app-icon {
		display: none !important;
	}
}

.sb-force-black {
	background-color: #000 !important;
}

.bg-black {
	background-color: #000;
}

.bg-white {
	background-color: #fff;
}

.bg-light-grey {
	background-color: #f7f8fa;
}

.bg-mid-grey {
	background-color: $ui-mid-grey;
}

.bg-grey {
	background-color: #f7f8fa;
}

.text-black {
	color: #000;
}

.text-white {
	color: #fff;
}

.hidden_x {
	overflow-x: hidden;
}

.no-border-bottom {
	border-bottom: none !important;
}

.no-br {
	border-right: none !important;
}

.slider_dots_gray {
	.slick-dots {
		bottom: 0;

		li {
			margin: 0;
		}
	}

	.slick-dots li.slick-active button::before {
		content: ' ';
		border-radius: 50%;
		background-color: $brand-primary;
		height: 10px;
		width: 10px;
		opacity: 1;
	}

	.slick-dots li button::before {
		content: ' ';
		height: 10px;
		width: 10px;
		opacity: 1;
		background-color: #efefef;
		border-radius: 50%;
	}
}

.hide_sm_down {
	display: none !important;
	@include md_up {
		display: inherit !important;
	}
}

.hide_sm_up {
	display: initial;
	@include sm_up {
		display: none;
	}
}

.hide_md {
	@include md-only {
		display: none;
	}
}

.hide_sm {
	@include sm-only {
		display: none;
	}
}

.hide_xs {
	@include xs-only {
		display: none;
	}
}

.sc {
	text-transform: capitalize;
}

.slider_dots_top {
	padding-top: 30px;
	margin-top: -6px;

	.slick-dots {
		top: 0;
		bottom: unset;
	}
}

.row-sm-3 {
	@include xs-only {
		> div {
			margin-bottom: 20px;
		}
	}
	@include sm-up {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: 20px;
		grid-row-gap: 60px;
	}
}

.row-sm-2 {
	@include xs-only {
		> div {
			margin-bottom: 20px;
		}
	}
	@include sm-up {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
	}
}

.row-sm-2-md-3 {
	display: grid;
	grid-gap: 20px;
	@include sm-up {
		grid-template-columns: 1fr 1fr;
	}
	@include md-up {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.row-sm-large-small {
	@include xs-only {
		> div {
			margin-bottom: 20px;
		}
	}
	@include sm-up {
		display: grid;
		grid-template-columns: 2fr 1fr;
		grid-gap: 20px;
	}
	@include md-up {
		> div {
			max-width: none;
		}
	}
}

.slider_dots_square {
	.slick-dots {
		button::before {
			border-radius: 0 !important;
		}
	}
}

.slider_dots_upsell {
	overflow: visible;

	.slick-dots {
		bottom: -29px !important;
		border-radius: 0 !important;

		li {
			margin: 0;
		}

		li:not(.slick-active) button::before {
			background: #c4c4c4 !important;
			content: ' ';
			height: 10px;
			width: 10px;
			opacity: 1;
			background-color: #efefef;
			border-radius: 0;
		}

		li.slick-active button::before {
			content: ' ';
			border-radius: 0;
			background-color: $brand-primary;
			height: 10px;
			width: 10px;
			opacity: 1;
		}
	}
}

.slider_dots_upsell_bundle {
	@extend .slider_dots_upsell;

	.slick-dots {
		bottom: -4px !important;
	}
}

.overflow_visible {
	overflow: visible !important;
}

.nuka_style {
	.paging-dot {
		border-radius: 0 !important;
		height: 10px !important;
		width: 10px !important;
		position: relative;
		top: 4px;
		background: #c4c4c4 !important;
	}

	.active .paging-dot {
		background-color: $brand-primary !important;
	}
}

.capitalize {
	text-transform: capitalize;
}

.sentence {
	text-transform: none;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.unbold {
	font-weight: 400 !important;
}

.title-mb {
	margin-bottom: 30px;
}

.subtitle-mb {
	margin-bottom: 15px;
}

.tac-xs {
	@include xs-only {
		text-align: center;
	}
}

.tac-smd {
	@include sm-down {
		text-align: center;
	}
}

.tac-not-sm {
	text-align: center;
	@include sm_only {
		text-align: initial;
	}
}

.mb-10-important {
	margin-bottom: 10px !important;
}

.rel {
	position: relative;
}

.hide_xs_grid {
	display: none !important;
	@include sm_up {
		display: grid !important;
	}
}

.ytp-button {
	display: none;
}

.flexed {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-lnk {
	@include buttonlink;
}

.btn-secondary {
	@include button-secondary;
}

.none_step {
	display: none;
}

.none_step_0 {
	display: none;
}

.none_step_1 {
	display: none;
}

.none_step_1_2 {
	display: none;
}

.none_step_2 {
	display: none !important;
}

.none_step_3 {
	display: none;
}

.none_step_05 {
	display: none;
}

.email_modal_invalid {
	display: none;
}

.phone_modal_invalid {
	display: none;
}

.w100 {
	width: 100% !important;
}

.promo-new-financing {
	img {
		display: inline-block;
		position: relative;
		width: 50px;
	}

	img.affirm {
		top: 1px;
	}

	span.temp {
		display: none;
		@include sm-up {
			display: inline;
		}
	}

	span.link {
		text-decoration: underline;
		color: #000;
		opacity: 0.5;
		@include max('370px') {
			display: none;
		}
	}
}

.thermocoverhome {
	img {
		object-fit: contain;
	}

	:nth-child(2) {
		img {
			object-fit: cover;
		}
	}
}

.grid-4-sm {
	@include sm-up {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 10px;
	}
}

.grid-4 {
	@include sm_up {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 10px;
		grid-template-rows: 1fr;
	}
	@include md-up {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 10px;
	}
}

img.corner-ribbon {
	width: 50%;
	max-width: 200px;
	position: absolute;
	top: 0;
	right: 0;
	height: auto !important;
	margin-top: 0 !important;
}

img.corner-ribbon-top-left {
	width: 50%;
	max-width: 200px;
	position: absolute;
	top: 0;
	left: 0;
	height: auto !important;
	margin-top: 0 !important;
}

img.corner-ribbon-bottom-right {
	width: 50%;
	max-width: 200px;
	position: absolute;
	bottom: 0;
	right: 0;
	height: auto !important;
	margin-top: 0 !important;
}

.layout-inline {
	@include sm_up {
		display: inline !important;
	}
}

.sr-only {
	display: none;
}

.skip-main {
	left: -999px;
	position: absolute;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
	z-index: -999;

	transition: none !important;
}

.skip-main:focus,
a.skip-main:active {
	color: #000;
	background-color: #fff;
	left: 10%;
	right: 10%;
	margin-top: 20px;
	top: auto;
	width: 80%;
	height: auto;
	overflow: auto;
	padding: 5px;
	border-radius: 15px;
	text-align: center;
	@include p-desktop;
	z-index: 99999;
	@include sm_up {
		width: 30%;
		margin: 10px 35%;
		left: auto;
		right: unset;
		@include h5-desktop;
	}
}

.w32 {
	width: 32px;
}

.mta {
	margin-top: auto;
}

.mla {
	margin-left: auto;
}

.mra {
	margin-right: auto;
}

.mba {
	margin-bottom: auto;
}

.p-desktop_large {
	@include md_up {
		@include p-large-desktop;
	}
}

.slide_dropdown_items {
	div {
		div {
			display: flex;
			flex-direction: column;

			a {
				margin-bottom: 16px;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				display: flex;
				align-items: center;
				text-transform: capitalize;
				color: #e5e5e5;

				&:first-child {
					margin-top: 33px;
				}
			}
		}
	}
}

.arrow_slide_down_up {
	width: 24px;
	height: 24px;
	position: absolute;
	right: 0;
	transform: rotate(-180deg) !important;
	@include md_up {
		right: 25px;
		bottom: 20px;
	}
}

.arrow_slide_down_down {
	width: 24px;
	height: 24px;
	position: absolute;
	right: 0;

	transform: rotate(-360deg) !important;
	@include md_up {
		right: 25px;
		bottom: 20px;
	}
}

.ywa-10000 {
	display: none !important;
}

.modal_container {
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 70%);
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: auto;
	position: fixed;
	height: 100vh;
	width: 100vw;
	z-index: 1300;
}

.heading.tac-hp {
	text-align: center;

	+ p {
		text-align: center;
	}
}

/* YOTPO */
.jdgm-rev__votes {
	display: none;
}

/* Kustomer */
#kustomer-ui-sdk-iframe {
	@include sm-down {
		visibility: hidden;
		display: none;
	}

	margin-bottom: var(--kustomer-height, 0);
	transition: width 200ms, height 200ms, max-height 200ms, transform 300ms cubic-bezier(0, 1.2, 1, 1), opacity 83ms ease-out, margin-bottom 0.25s ease-out !important;
}

#decagon-chat-bubble {
	@include sm-down {
		visibility: hidden;
		display: none;
	}

	position: fixed;
	right: 20px;
	bottom: 20px;

	z-index: 20;

	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	filter: drop-shadow(rgba(0, 0, 0, 0.3) 1px 1px 3px);

	&:hover {
		transform: translateY(-4px) scale(1.04);
	}

	width: 80px;
	height: 80px;

	img {
		width: 60px;
		height: 60px;
	}

	transition: width 200ms, height 200ms, max-height 200ms, transform 300ms cubic-bezier(0, 1.2, 1, 1), opacity 83ms ease-out, margin-bottom 0.25s ease-out !important;

	margin-bottom: var(--kustomer-height, 0) !important;
}

.absolute-cover {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

/* Slider */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	user-select: none;
	user-select: none;
	user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	user-select: none;
	touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track::before,
.slick-track::after {
	display: table;
	content: '';
}

.slick-track::after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

[dir='rtl'] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

.region_debug_menu {
	border-radius: 10px;
	background: linear-gradient(33deg, rgb(255 192 233 / 75%), rgb(253 192 255 / 75%));
	backdrop-filter: blur(15px);
	box-shadow: rgb(0 0 0 / 25%) 3px 3px 5px 0;
	color: black;
	position: fixed;
	bottom: 0;
	left: 0;
	margin: 25px;
	padding: 25px;
	z-index: 1000;

	ul {
		list-style: none;
		margin: 0;
	}
}

.minh-730 {
	@include md-up {
		.customizer {
			min-height: 730px;
		}
	}
}

.relative-10 {
	position: relative;
	z-index: 10;
}

.inverted {
	filter: invert(1);
}

.mobile_tablet_only {
	display: none;
	@include sm-down {
		display: unset;
	}
}

.desktop_only {
	@include sm-down {
		display: none;
	}
}

.mobile_only {
	@include sm-up {
		display: none;
	}
}

img[src*='svg'] {
	-webkit-transform: translate3d(0, 0, 0);
}

.op0 {
	opacity: 0;
}

.anchor_offset {
	position: absolute;
}

#zowieFloatingButton,
#zowieFloatingButtonMobile {
	@include sm-down {
		display: none !important;
	}
}

.topPaddingShop {
	padding-top: 4rem;
	@include sm-down {
		padding-top: 0;
	}
}

.isolate {
	position: relative;
	z-index: 1;
}

.app-terms-conditions {
	ol {
		padding-left: 30px;
		padding-bottom: 30px;
		font-size: 16px;
		line-height: 26px;
	}
}

.affirmTransform {
	transform: translateY(-0.15em);
	height: 20px;
}

.klarnaTransform {
	transform: translateY(-15%);
	height: 20px;
}

.no-focus-outline {
	outline: none;
}

#affirm-info-cta {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	opacity: 0;
}

.hide_one_trust {
	#onetrust-consent-sdk {
		display: none;
	}
}
