.hideMobile {
	@include sm-down {
		display: none;
	}
}
.hideSmUp {
	@include sm-up {
		display: none !important;
	}
}
.hideMdUp {
	@include lg-up {
		display: none;
	}
}
.hideLgUp {
	@include lg-up {
		display: none;
	}
}
.hideXlUp {
	@include xl-up {
		display: none;
	}
}
.hideXs {
	display: none !important;
	@include sm-up {
		display: block !important;
	}
}
.hide-xs {
	display: none;
	@include sm-up {
		display: initial;
	}
}
.hideDesktop {
	@include md-up {
		display: none !important;
	}
}
[MEDIA_QUERY_SMALL_ONLY] {
	display: none;
	@include sm-only {
		display: initial;
	}
}
.hideSm {
	display: initial;
	@include sm-only {
		display: none;
	}
}
.hideMdDown {
	display: none;
	@include lg-up {
		display: initial;
	}
}
.hideSmDown {
	display: none;
	@include sm-up {
		display: initial;
	}
}

.hideSmDownInclusive {
	display: none;
	@include md-up {
		display: initial;
	}
}

.hide-360 {
	display: none;
	@include max(360px) {
		display: initial;
	}
}

.show-360 {
	@include max(360px) {
		display: none;
	}
}
