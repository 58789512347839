@import './mixins/fonts_legacy';
@import './mixins/media-queries';

h1,
.h1 {
	text-rendering: optimizelegibility;
	margin-bottom: 15px;
	@include h1-mobile;
	@include sm-up {
		margin-bottom: 30px;
		@include h1-desktop;
	}
}

h2,
.h2 {
	@include h2-mobile;
	margin-bottom: 15px;
	@include sm-up {
		margin-bottom: 30px;
		@include h2-desktop;
	}
}

h3,
.h3 {
	@include h3-mobile;
	margin-bottom: 15px;
	@include sm-up {
		margin-bottom: 30px;
		@include h3-desktop;
	}
}

h4,
.h4 {
	@include h4-mobile;
	margin-bottom: 15px;
	@include sm-up {
		margin-bottom: 30px;
		@include h4-desktop;
	}
}

h5,
.h5 {
	margin-bottom: 10px;
	@include h5-mobile;
	@include sm-up {
		margin-bottom: 20px;
		@include h5-desktop;
	}
}

h6,
.h6 {
	margin-bottom: 10px;
	@include h6-mobile;
	@include sm-up {
		margin-bottom: 20px;
		@include h6-desktop;
	}
}

.h7 {
	margin-bottom: 10px;
	@include h7-mobile;
	@include sm-up {
		@include h7-desktop;
	}
}

.h7-mobile {
	margin-bottom: 10px;
	@include h7-mobile;
}

p,
.p {
	padding: 0;
	margin: 0;
	margin-bottom: 30px;
	@include p-mobile;

	@include sm-up {
		@include p-desktop;
	}

	&.p2,
	&.small {
		@include p-small-mobile;
		@include sm-up {
			@include p-small-desktop;
		}
	}

	&.big,
	&.large {
		@include p-large-mobile;
		@include sm-up {
			@include p-large-desktop;
		}
	}

	&.tiny {
		@include p-tiny-mobile;
		@include sm-up {
			@include p-tiny-desktop;
		}
	}
}

.quote {
	@include quote-mobile;
	@include sm-up {
		@include quote-tablet;
	}
	@include md-up {
		@include quote-desktop;
	}
}

.header12 {
	font-family: var(--neue-montreal), sans-serif;
	font-size: 28px;
	letter-spacing: 0.5px;
	line-height: 35px;
	font-weight: 500;
	@include md-up {
		font-family: var(--neue-montreal), sans-serif;
		font-size: 32px;
		letter-spacing: 0.5px;
		line-height: 40px;
		font-weight: 500;
	}
}

.header13 {
	font-family: var(--neue-montreal), sans-serif;
	font-size: 28px;
	letter-spacing: 0.5px;
	line-height: 28px;
	font-weight: 600;
}

.quote-style-1 {
	font-family: var(--neue-montreal), sans-serif;
	font-size: 26px;
	letter-spacing: 0.5px;
	line-height: 32px;
	font-style: italic;
	font-weight: 300;
	@include sm-up {
		font-family: var(--neue-montreal), sans-serif;
		font-size: 34px;
		letter-spacing: 0.5px;
		line-height: 40px;
		font-weight: 300;
		font-style: italic;
	}
	@include md-up {
		font-family: var(--neue-montreal), sans-serif;
		font-size: 43px;
		letter-spacing: 0.5px;
		line-height: 50px;
		font-weight: 300;
		font-style: italic;
	}
}

.quote-style-2 {
	font-family: var(--neue-montreal), sans-serif;
	font-size: 16px;
	letter-spacing: 0.2px;
	line-height: 24px;
	font-style: italic;
	font-weight: 300;
	@include sm-up {
		font-family: var(--neue-montreal), sans-serif;
		font-size: 20px;
		letter-spacing: 0.5px;
		line-height: 24px;
		font-weight: 300;
		font-style: italic;
	}
	@include md-up {
		font-family: var(--neue-montreal), sans-serif;
		font-size: 24px;
		letter-spacing: 0.5px;
		line-height: 34px;
		font-weight: 300;
		font-style: italic;
	}
}

.quote-style-3 {
	font-family: var(--neue-montreal), sans-serif;
	font-size: 28px;
	letter-spacing: normal;
	line-height: 35px;
	font-style: normal;
	font-weight: 300;
	@include sm-up {
		font-family: var(--neue-montreal), sans-serif;
		font-size: 28px;
		letter-spacing: normal;
		line-height: 35px;
		font-style: normal;
		font-weight: 300;
	}
	@include md-up {
		font-family: var(--neue-montreal), sans-serif;
		font-size: 28px;
		letter-spacing: normal;
		line-height: 35px;
		font-style: normal;
		font-weight: 300;
	}
}

.shop_option_title_font_only {
	@include h6-desktop;
	text-align: center;
	text-transform: uppercase;
	line-height: 28px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	@include md_up {
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 1.3px;
	}
}

.shop_option_title_new {
	@include h6-desktop;
	text-align: center;
	background-color: #000 !important;
	color: #fff !important;
	text-transform: uppercase;
	line-height: 28px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	@include md_up {
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 1.3px;
	}
}

.shop_option_title {
	font-size: 18px;
	font-weight: bold;
	line-height: 28px;
	text-transform: uppercase;
	@include xs-only {
		font-size: 16px;
		line-height: 20px;
	}
}

.shop_option_title_refresh {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.banner_shop_page {
	@include h6-desktop;
	text-align: center;
	background-color: #000 !important;
	color: #fff !important;
	margin: 0;
	text-transform: uppercase;
	line-height: 28px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	@include md_up {
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 1.3px;
		padding: 0;
	}
}

.line_height_small {
	padding-bottom: 0;
	margin-bottom: 0;
	line-height: 1.25;
	font-weight: 500;
}

.accessory_quote {
	text-transform: capitalize;
	font-weight: bold;
	margin-bottom: 0;
	@include md_up {
		font-size: 32px;
		line-height: 48px;
	}
}
