@import 'styles/colors';

$break_sm: 600;
$break_md: 1024;
$break_lg: 1440;
$break_xl: 1920;

// https://codepen.io/sosuke/pen/Pjoqqp
$gold-filter: brightness(0) saturate(0) invert(60%) sepia(41%) saturate(581%) hue-rotate(2deg) brightness(87%) contrast(93%);
$light-gold-filter: brightness(0) saturate(0) invert(93%) sepia(13%) saturate(610%) hue-rotate(346deg) brightness(96%) contrast(94%);
$navy-blue-filter: brightness(0) saturate(0) invert(13%) sepia(32%) saturate(6822%) hue-rotate(218deg) brightness(85%) contrast(109%);
$white-filter: brightness(0) saturate(0) invert(93%) sepia(0%) saturate(7493%) hue-rotate(312deg) brightness(108%) contrast(103%);

$xs_margin: 24px;
$sm_margin: 38px;
$md_margin: 76px;

$header_height: 55px;
$offer_bar_height: 46px;

$z_index_header: 300;
$z_index_kustomer: 900;
$z_index_site_overlay: 1000;
$z_index_cart: 1100;
$z_index_loading: 1200;
$z_index_modal: 1300;
$z_index_header_dropdown: 250;

$container-padding-bottom: 40px;
$container-padding-top: 40px;

$md_primary_padding: 90px;
$xs_primary_padding: 60px;

$md_secondary_padding: 60px;
$xs_secondary_padding: 40px;
