@mixin h1-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 80px;
	line-height: 1;
	letter-spacing: -0.5px;
}

@mixin h1-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 60px;
	line-height: 80px;
}

@mixin h2-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 56px;
	letter-spacing: 0.5px;
}

@mixin h2-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 36px;
}

@mixin h3-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 48px;
}

@mixin h3-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 35px;
}

@mixin h4-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	line-height: 40px;
}

@mixin h4-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
}

@mixin h5-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 30px;
}

@mixin h5-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 26px;
}

@mixin h6-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 28px;
}

@mixin h6-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 22px;
}

@mixin h7-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
}

@mixin h7-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
}

@mixin p-large-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 22px;
	line-height: 26px;
}

@mixin p-large-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 28px;
}

@mixin p-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
}

@mixin p-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

@mixin p-small-desktop {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
}

@mixin p-small-mobile {
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
}

@mixin quote-desktop {
	font-size: 43px;
	line-height: 50px;
	font-weight: 300;
	letter-spacing: normal;
	text-transform: none;
	font-family: var(--neue-montreal), sans-serif;
}

@mixin quote-tablet {
	font-size: 36px;
	line-height: 40.6px;
	font-weight: 300;
	letter-spacing: normal;
	text-transform: none;
	font-family: var(--neue-montreal), sans-serif;
}

@mixin quote-mobile {
	font-size: 28px;
	line-height: 32.1px;
	font-weight: 300;
	letter-spacing: normal;
	text-transform: none;
	font-family: var(--neue-montreal), sans-serif;
}

@mixin p-tiny-desktop {
	font-size: 10px;
	line-height: 14px;
	font-family: var(--neue-montreal), sans-serif;
	font-weight: normal;
	letter-spacing: 0;
}

@mixin p-tiny-mobile {
	font-size: 10px;
	line-height: 14px;
	font-family: var(--neue-montreal), sans-serif;
	font-weight: normal;
	letter-spacing: 0;
}

@mixin font-aktiv-light {
	font-family: var(--neue-montreal), sans-serif;
	font-weight: 300;
}

@mixin font-aktiv-regular {
	font-family: var(--neue-montreal), sans-serif;
	font-weight: 400;
}

@mixin font-aktiv-medium {
	font-family: var(--neue-montreal), sans-serif;
	font-weight: 500;
}

@mixin font-aktiv-bold {
	font-family: var(--neue-montreal), sans-serif;
	font-weight: 700;
}
