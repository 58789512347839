.tippy-box[data-theme~='grey'] {
	color: white;
	/*box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15), 0 4px 80px -8px rgba(36, 40, 47, 0.25), 0 4px 4px -2px rgba(91, 94, 105, 0.15);*/
	background-color: #f1f2f4;
}

.tippy-box[data-theme~='grey'][data-placement^='top'] > .tippy-arrow:before {
	border-top-color: #f1f2f4;
}

.tippy-box[data-theme~='grey'][data-placement^='bottom'] > .tippy-arrow:before {
	border-bottom-color: #f1f2f4;
}

.tippy-box[data-theme~='grey'][data-placement^='left'] > .tippy-arrow:before {
	border-left-color: #f1f2f4;
}

.tippy-box[data-theme~='grey'][data-placement^='right'] > .tippy-arrow:before {
	border-right-color: #f1f2f4;
}

.tippy-box[data-theme~='grey'] > .tippy-backdrop {
	background-color: #f1f2f4;
}

.tippy-box[data-theme~='light'] > .tippy-svg-arrow {
	fill: #fff;
}
