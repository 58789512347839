// @import 'mixins/fonts_legacy'

.badge_v1 {
	// background-color: $brand-primary;
	// color: #fff;
	white-space: pre-wrap !important;
	background-color: $brand-primary;
	color: #fff;
	text-transform: uppercase;
	display: inline-block;
	padding: 5px 14px 4px 14px;
	border-radius: 100px;
	font-family: var(--neue-montreal), sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.5px;
	margin-bottom: 0;
}

.badge_v1_gradient {
	background: linear-gradient(113.7deg, #1862ff -64.08%, #ff00d6 220.18%);
	border-radius: 5px;
	color: white;
}

.badge_v2 {
	@include p-small-desktop;
	color: $brand-secondary;
	&.icon {
		&:before {
			display: inline-block;
			height: 16px;
			width: 16px;
			content: ' ';
			background-image: url('https://eightsleep.imgix.net/tag-outline-16.svg?v=1623412593');
			background-repeat: no-repeat;
			background-size: contain;
			margin-right: 6px;
			position: relative;
			top: 3px;
		}
	}
}
